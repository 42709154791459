/* eslint-disable no-unused-vars */
import Vue from "vue";
import CapacityForm from "@/components/needcapacityform/needcapacityform.vue";
//import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
//import { required, decimal, numeric } from "vuelidate/lib/validators";
//import { particlesSetup, styleMapSetup } from "@/utils/setup";
import { BSkeleton, BSkeletonWrapper } from "bootstrap-vue";
import { getUserProfile } from "@/services/api/workUsers";

export default {
  name: "needcapacity",
  data() {
    return {
      loadingHits: true,
    };
  },
  components: {
    "need-capacity-form": CapacityForm,
    "b-skeleton": BSkeleton,
    "b-skeleton-wrapper": BSkeletonWrapper,
    //FormGroup
  },
  created() {
    console.info(
      "CREATED assignment form",
      //this.$store.getters["assignment/assignmentForm"]
    );
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {},
  watch: {
    $route(to, from) {
      // Call resizePreserveCenter() on all maps
      //Vue.$gmapDefaultResizeBus.$emit("resize");
    },
  },
  computed: {
    //google: gmapApi,
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    id: () => {
      return this.$route.params.id;
    },
  },
  methods: {
    //postAssignment,
    getUserProfile,
  },
};
